import React from 'react';
import ReactDOM from 'react-dom/client';

import { createBrowserRouter, RouterProvider } from 'react-router-dom';

import './assets/scss/main.scss';

import PageStart from './Start/PageStart';
import PageLogin from './Login/PageLogin';

const router = createBrowserRouter([
    {
        path: '/',
        element: <PageStart />
    },
    {
        path: '/login',
        element: <PageLogin />
    }
]);

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
    <React.StrictMode>
        <RouterProvider router={router} />
    </React.StrictMode>
);

import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

function PageLogin() {
    const isLoggedIn = useCallback(() => {
        const token = localStorage.getItem('user_token');
        return token !== null;
    }, []);

    const [isLoading, setIsLoading] = useState(false);
    const navigate = useNavigate();

    function submit(e) {
        e.preventDefault();

        const username = e.target.username.value;
        const password = e.target.password.value;

        if (username === '' || password === '') return;

        setIsLoading(true);

        fetch('http://localhost:3001/login', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            },
            body: new URLSearchParams({
                username: username,
                password: password
            })
        })
            .then(async response => {
                setIsLoading(false);

                const data = await response.json();

                if (response.status !== 200 || data.token === undefined || data.token === null) {
                    alert('Deze gebruikersnaam en/of wachtwoord is onjuist. Probeer het opnieuw.');
                    return;
                }

                localStorage.setItem('user_token', data.token);
                navigate('/');
            })
            .catch(error => {
                setIsLoading(false);
                console.error(error);
            });
    }

    useEffect(() => {
        if (isLoggedIn()) {
            navigate('/');
        }
    }, [isLoggedIn, navigate]);

    return (<>
        <div className="login-page">
            <div className="login-page__inner">
                <form className={'login-page__form' + (isLoading ? ' loading' : '')} onSubmit={submit}>
                    <div className="form-wrapper">
                        <input className="input username" type="text" name="username" placeholder="Gebruikersnaam" />
                        <input className="input password" type="password" name="password" placeholder="Wachtwoord" />
                        <button type="submit" className="btn">Inloggen</button>
                    </div>
                    {isLoading && <div className="login-page__loading">Momentje..</div>}
                </form>
            </div>
        </div>
    </>);
}

export default PageLogin;
import React, { useEffect, useState, useRef, useCallback } from 'react';

import ProgramModal from '../components/ProgramModal';
import ModeModal from '../components/ModeModal';
import { useNavigate } from 'react-router-dom';
import TemperatureDial from './components/TemperatureDial';

function PageStart() {
    const navigate = useNavigate();

    const [mainState, setMainState] = useState(null);
    const [currentModeSlug, setCurrentModeSlug] = useState('off');
    const [showModeModal, setShowModeModal] = useState(false);
    const [showProgramModal, setShowProgramModal] = useState(false);
    const [dialMode, setDialMode] = useState('current');

    const abortControllerRef = useRef(null);
    const pageWrapperRef = useRef(null);
    
    const programModes = [
        { slug: 'constant', title: 'Constant', className: null },
        { slug: 'program', title: 'Programma', className: null },
        { slug: 'heat-up-to', title: 'Verwarm naar temperatuur', className: null },
        { slug: 'off', title: 'Uitgeschakeld', className: 'text-danger' }
    ];

    const currentMode = programModes.find(mode => mode.slug === currentModeSlug);

    function getUserToken() {
        return localStorage.getItem('user_token');
    }

    function getDisplayTempArray() {
        if (mainState === null) return [0, 0];
        if (dialMode === 'current') return mainState.settings.current_room_temperature.toString().split('.');
        return mainState.currentSetTemperature.toString().split('.');
    }

    function changeMode(mode) {
        const oldModeSlug = currentModeSlug;

        setShowModeModal(false);
        setCurrentModeSlug(mode);
        setDialMode('current');

        const urlencoded = new URLSearchParams();
        urlencoded.append('mode', mode);

        fetch('http://localhost:3001/set-current-mode', {
            method: 'PUT',
            headers: { 'Content-Type': 'application/x-www-form-urlencoded', 'Authorization': `Bearer ${getUserToken()}` },
            body: urlencoded
        })
            .then(async response => {
                if (response.status === 401) return;

                if (response.status !== 200) {
                    setCurrentModeSlug(oldModeSlug);
                    alert('Oeps, er ging iets fout. Probeer het nogmaals.');
                    return;
                }

                const data = await response.json();
                setCurrentModeSlug(data.mode);
            })
            .catch(error => {
                console.error('Error:', error);
                setCurrentModeSlug(oldModeSlug);
            });
    }

    const setConstantTemperature = useCallback((temp) => {
        const urlencoded = new URLSearchParams();
        urlencoded.append('temperature', temp);

        fetch('http://localhost:3001/set-constant-temperature', {
            method: 'PUT',
            headers: { 'Content-Type': 'application/x-www-form-urlencoded', 'Authorization': `Bearer ${getUserToken()}` },
            body: urlencoded
        })
            .then(async response => {
                if (response.status === 401) return;

                if (response.status !== 200) {
                    alert('Oeps, er ging iets fout. Probeer het nogmaals.');
                    return;
                }
            })
            .catch(error => {
                console.error('Error:', error);
            });
    }, []);

    function deleteAndAddProgramRow(oldRow, newRow) {
        const urlencoded = new URLSearchParams();
        urlencoded.append('row', JSON.stringify(oldRow));

        fetch('http://localhost:3001/delete-program-row', {
            method: 'DELETE',
            headers: { 'Content-Type': 'application/x-www-form-urlencoded', 'Authorization': `Bearer ${getUserToken()}` },
            body: urlencoded
        })
            .then(async response => {
                if (response.status === 401) return;

                if (response.status !== 200) {
                    alert('Oeps, er ging iets fout. Probeer het nogmaals.');
                    return;
                }

                // Set the new row
                const urlencoded = new URLSearchParams();
                urlencoded.append('row', JSON.stringify(newRow));
        
                fetch('http://localhost:3001/set-program-row', {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/x-www-form-urlencoded', 'Authorization': `Bearer ${getUserToken()}` },
                    body: urlencoded
                })
                    .then(async response => {
                        if (response.status === 401) return;
        
                        if (response.status !== 200) {
                            alert('Oeps, er ging iets fout. Probeer het nogmaals.');
                            return;
                        }
        
                        const data = await response.json();
                        if (data) setMainState(oldState => { return { ...oldState, programRows: data } });
                    })
                    .catch(error => {
                        console.error('Error:', error);
                        alert('Oeps, er ging iets fout. Probeer het nogmaals.');
                    });
            })
            .catch(error => {
                console.error('Error:', error);
                alert('Oeps, er ging iets fout. Probeer het nogmaals.');
            });
    }

    function deleteProgramRow(row) {
        const urlencoded = new URLSearchParams();
        urlencoded.append('row', JSON.stringify(row));

        fetch('http://localhost:3001/delete-program-row', {
            method: 'DELETE',
            headers: { 'Content-Type': 'application/x-www-form-urlencoded', 'Authorization': `Bearer ${getUserToken()}` },
            body: urlencoded
        })
            .then(async response => {
                if (response.status === 401) return;

                if (response.status !== 200) {
                    alert('Oeps, er ging iets fout. Probeer het nogmaals.');
                    return;
                }

                const data = await response.json();
                if (data) setMainState(oldState => { return { ...oldState, programRows: data } });
            })
            .catch(error => {
                console.error('Error:', error);
                alert('Oeps, er ging iets fout. Probeer het nogmaals.');
            });
    }

    useEffect(() => {
        if (mainState === null) return;
        setCurrentModeSlug(mainState.settings.current_mode);
    }, [mainState]);

    useEffect(() => {
        if (abortControllerRef.current !== null) {
            abortControllerRef.current.abort();
        }
    }, []);

    useEffect(() => {
        const fetchMainState = () => {
            if (abortControllerRef.current !== null) abortControllerRef.current.abort();
            abortControllerRef.current = new AbortController();

            fetch('http://localhost:3001/thermostat-status', {
                signal: abortControllerRef.current.signal,
                method: 'GET',
                headers: { 'Authorization': `Bearer ${getUserToken()}` },
            })
                .then(async response => {
                    if (response.status === 401) {
                        localStorage.removeItem('user_token');
                        navigate('/login');
                        return;
                    }

                    if (response.status !== 200) {
                        alert('Oeps, er ging iets fout. Sluit de pagina en probeer het nogmaals.');
                        return;
                    }

                    const data = await response.json();
                    setMainState(oldState => { return { ...oldState, ...data } });
                })
                .catch(error => console.error(error));
        };

        fetchMainState();
        const interval = setInterval(fetchMainState, 3000);

        return () => {
            if (abortControllerRef.current !== null) abortControllerRef.current.abort();
            clearInterval(interval);
        }
    }, [navigate]);

    return (<>
        <div ref={pageWrapperRef}>
            <div className="current-mode">
                <button className="current-mode__text" onClick={() => setShowModeModal(true)}>{currentMode.title}</button>
            </div>

            <TemperatureDial dialMode={dialMode} setDialMode={setDialMode} mainState={mainState} setConstantTemperature={setConstantTemperature} getDisplayTempArray={getDisplayTempArray} />

            <button type="button" className="edit-program-btn" onClick={() => setShowProgramModal(true)}>Pas programma aan</button>

            <ModeModal show={showModeModal} modes={programModes} currentModeSlug={currentModeSlug} changeMode={changeMode} />
            <ProgramModal show={showProgramModal} setShow={setShowProgramModal} programRows={mainState?.programRows} deleteAndAddProgramRow={deleteAndAddProgramRow} deleteProgramRow={deleteProgramRow} />
        </div>
    </>);
}

export default PageStart;

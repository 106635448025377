import React from 'react';

function ModeModal({ show, modes, currentModeSlug, changeMode }) {
    return (<>
        <div className={'mode-modal' + (show ? ' open' : '')}>
            <div className="mode-modal__inner">
                <ul className="mode-modal__list">
                    {modes.map(mode => {
                        return <li key={`mode-modal-mode-${mode.slug}`} className={'mode-modal__mode' + (mode.slug === currentModeSlug ? ' current' : '')}><button type="button" onClick={() => changeMode(mode.slug)}>{mode.title}</button></li>
                    })}
                </ul>
            </div>
        </div>
    </>);
}

export default ModeModal;
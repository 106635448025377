import React, { useState, useEffect, useRef } from 'react';
import gsap from 'gsap';
import Draggable from 'gsap/Draggable';
import { useGSAP } from '@gsap/react';

gsap.registerPlugin(Draggable);

function TemperatureDial({ dialMode, setDialMode, mainState, setConstantTemperature, getDisplayTempArray }) {
    const dialElRef = useRef(null);    
    const draggableRef = useRef(null);

    const isDrawingRef = useRef(false);
    const [isDrawing, setIsDrawing] = useState(false);
    const [drawingTemp, setDrawingTemp] = useState(0);

    const maxRotation = 260;
    const maxTemp = 40;

    const toggleDialMode = () => {
        setDialMode(prevValue => prevValue === 'current' ? 'program' : 'current');
    };

    function updateRotation(rotation, sendUp) {
        const percent = rotation / maxRotation * 100;
        const temp = Math.round((maxTemp / 100 * percent) * 2) / 2;

        setDrawingTemp(temp);

        if (sendUp) {
            setConstantTemperature(temp);
            setDialMode('current');
        }
    };

    function getDrawingTempArray() {
        return drawingTemp.toString().split('.');
    };

    useEffect(() => {
        isDrawingRef.current = isDrawing;
    }, [isDrawing]);

    useEffect(() => {
        if (mainState === null || isDrawingRef.current) return;

        const tempPercentage = mainState.currentSetTemperature / maxTemp * 100;
        const rotationToSet = (260 / 100 * tempPercentage);

        gsap.killTweensOf(draggableRef.current[0].target, { rotation: true });
        gsap.to(draggableRef.current[0].target, 1.5, { rotation: rotationToSet, ease: 'power2.inOut',  })
    }, [mainState]);

    useGSAP(() => {
        draggableRef.current = Draggable.create('.jsDial', {
            type: 'rotation',
            throwProps: true,
            bounds: { minRotation: 0, maxRotation: maxRotation },

            onDragStart: function () {
                setDialMode('program');
                setIsDrawing(true);

                updateRotation(this.rotation, false);
            },
            onDrag: function () {
                updateRotation(this.rotation, false);
            },
            onDragEnd: function () {
                setIsDrawing(false);
                updateRotation(this.rotation, true);
            },
            onClick: function () {
                toggleDialMode();
            }
        });
    }, { scope: dialElRef });

    return (
        <div ref={dialElRef} className={'temp-display' + (dialMode === 'program' ? ' temp-display--switch' : '')}>
            <div className={'temp-display__heater-on' + (mainState?.isHeatingOn ? ' on' : '')}><svg xmlns="http://www.w3.org/2000/svg" width="50" height="68.77" viewBox="0 0 50 68.77"><g><path className="cls-1" d="M34,68.77C47.49,64,52.71,52.53,48.66,37.55,44.28,21.35,32.34,10.72,21.16,0,16.32,9.47,11.59,18.55,7.05,27.72,4.77,32.31,2.22,36.91.89,41.8-2.24,53.27,3,63.21,14.49,67.25c-9.26-11-1.11-19.43,2.57-30.06L23.77,47.9c2.08-8.45,3.76-15.27,5.41-21.94C43.09,35.61,45.37,52.68,34,68.77Z" /></g></svg></div>

            <span className="temp-display__label">{dialMode === 'current' ? 'Op dit moment' : 'Ingesteld op'}</span>

            {!isDrawing
                ? <span className="temp-display__current"><span className="main">{getDisplayTempArray()[0]}</span><span className="sub">{getDisplayTempArray()[1]}</span></span>
                : <span className="temp-display__current"><span className="main">{getDrawingTempArray()[0]}</span><span className="sub">{getDrawingTempArray()[1]}</span></span>
            }

            <span className="temp-display__humidity">{mainState?.settings.current_room_humidity}%</span>
            <div className="temp-display__dial-wrapper"><div className="temp-display__dial jsDial"></div></div>
        </div>
    );
}

export default TemperatureDial;
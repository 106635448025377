import React, { useState, useEffect } from 'react';

import RangeSlider from 'react-range-slider-input';
import 'react-range-slider-input/dist/style.css';

const daysOfWeek = [
    { index: 1, name: 'Maandag' },
    { index: 2, name: 'Dinsdag' },
    { index: 3, name: 'Woensdag' },
    { index: 4, name: 'Donderdag' },
    { index: 5, name: 'Vrijdag' },
    { index: 6, name: 'Zaterdag' },
    { index: 0, name: 'Zondag' }
];

function ProgramModal({ show, setShow, programRows, deleteAndAddProgramRow, deleteProgramRow }) {
    const [rowsFormatted, setRowsFormatted] = useState({});
    const [viewMode, setViewMode] = useState('program');

    const tempCorrection = 8;
    const tempOptions = Array.from({ length: 26 - tempCorrection }, (_, i) => i);

    const [newBlockDay, setNewBlockDay] = useState(1);
    const [newBlockStartTime, setNewBlockStartTime] = useState(9.0);
    const [newBlockEndTime, setNewBlockEndTime] = useState(14.0);
    const [newBlockTemp, setNewBlockTemp] = useState(22);
    const [editBlock, setEditBlock] = useState(null);

    useEffect(() => {
        if (!programRows) return;
        const days = {};

        daysOfWeek.forEach((day) => {
            day.rows = [];
            days[day.index] = day;
        });

        const programDays = Object.keys(programRows);

        programDays.forEach((day) => {
            programRows[day].forEach((row) => {
                days[row.day_of_week].rows.push(row);
            });
        });

        setRowsFormatted(days);
    }, [programRows]);

    function close() {
        if (viewMode === 'program') return setShow(false);
        return setViewMode('program');
    }

    function setNewTimeblockTemp(temp) {
        setNewBlockTemp(temp);
    }

    function addZero(number) {
        return number < 10 ? '0' + number : number;
    }

    function formatTime(time) {
        const split = String(time).split('.');

        if (split.length === 1) return addZero(time) + ':00';
        return addZero(split[0]) + ':' + (split[1] === '5' ? '30' : '00');
    }

    function convertTimeNotationToFloat(time) {
        const split = time.split(':');
        return parseFloat(split[0]) + (split[1] === '30' ? 0.5 : 0);
    }

    function updateNewBlockTime(value) {
        setNewBlockStartTime(value[0]);
        setNewBlockEndTime(value[1]);
    }

    function showAddTimeBlockScreen(dayOfWeek) {
        setNewBlockDay(dayOfWeek);
        setNewBlockStartTime(9);
        setNewBlockEndTime(12);
        setNewBlockTemp(22);
        setEditBlock(null);

        setViewMode('add-new-timeblock');
    }

    function showUpdateTimeblockScreen(block) {
        setNewBlockDay(block.day_of_week);
        setNewBlockStartTime(convertTimeNotationToFloat(block.start_time));
        setNewBlockEndTime(convertTimeNotationToFloat(block.end_time));
        setNewBlockTemp(block.temperature);
        setEditBlock({...block});

        setViewMode('edit-timeblock');
    }

    function saveBlock() {
        const newBlock = {
            day_of_week: newBlockDay,
            end_time: formatTime(newBlockEndTime),
            start_time: formatTime(newBlockStartTime),
            temperature: newBlockTemp
        };

        deleteAndAddProgramRow(editBlock || newBlock, newBlock);

        // TODO: Should we call an action to reload the state?
        // TODO: Should we show a loader and wait until we get a new state?

        setViewMode('program');
    }

    function deleteBlock() {
        deleteProgramRow(editBlock);
        setViewMode('program');

        // TODO: Should we call an action to reload the state?
        // TODO: Should we show a loader and wait until we get a new state?

    }

    return (
        <div className={'program-modal' + (show ? ' open' : '')} data-current-day="1">
            <button type="button" className="program-modal__close-btn jsCloseEditProgramBtn" onClick={close}>Sluiten</button>

            <div className="program-modal__inner">
                {viewMode === 'program' &&
                    <div className="program-modal__days">
                        {Object.values(rowsFormatted).map((day) => (
                            <div key={`epm-day-${day.index}`} className="program-modal__day day">
                                <span className="day__title">{day.name}</span>

                                <div className="day__timeblocks">
                                    {day.rows.map((row) => (
                                        <button key={`epm-row-${day.name}-${row.start_time}`} type="button" className="day__timeblock" onClick={() => showUpdateTimeblockScreen(row)}>
                                            <span className="time">{row.start_time} - {row.end_time}</span>
                                            <span className="temperature">{row.temperature}&deg;</span>
                                        </button>
                                    ))}

                                    <button type="button" className="day__add-time" onClick={() => showAddTimeBlockScreen(day.index)}>+ Tijdvak toevoegen</button>
                                </div>
                            </div>
                        ))}
                    </div>
                }

                {(viewMode === 'add-new-timeblock' || viewMode === 'edit-timeblock') &&
                    <div className="program-modal__add-timeblock">
                        <span className="day">{daysOfWeek.find(day => day.index === newBlockDay).name}</span>

                        <span className="title">Tijdvak</span>

                        <div className="time-select">
                            <div className="time-select__clock">
                                <span className="time">{formatTime(newBlockStartTime)}</span>
                                <span>–</span>
                                <span className="time">{formatTime(newBlockEndTime)}</span>
                            </div>

                            <div className="time-select__range">
                                <RangeSlider min={0} max={24} step={0.5} value={[newBlockStartTime, newBlockEndTime]} onInput={updateNewBlockTime} />
                            </div>
                        </div>

                        <span className="title">Temperatuur</span>

                        <div className="temperature-rows">
                            {tempOptions.map((temp) => <button key={`epm-temp-${temp}`} type="button" className={'day__timeblock' + (newBlockTemp === (temp + tempCorrection) ? ' active' : '')} onClick={() => setNewTimeblockTemp(temp + tempCorrection)} dangerouslySetInnerHTML={{ __html: `${temp + tempCorrection}&deg` }}></button>)}
                        </div>

                        <button type="button" className="save-row" onClick={saveBlock}>{ viewMode === 'edit-timeblock' ? 'Opslaan' : 'Toevoegen' }</button>

                        {viewMode === 'edit-timeblock' && <button type="button" className="delete-row" onClick={deleteBlock}>Tijdvak verwijderen</button>}
                    </div>
                }
            </div>
        </div>
    );
}

export default ProgramModal;